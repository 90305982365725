import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  CardMedia,
  useMediaQuery,
  useTheme,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import FeatureComparisonMatrix from './components/FeatureComparisonMatrix';
import FreelancerSubscriptions from './components/FreelancerSubscriptions';
import Freelancer1 from 'src/assets/images/mood/freelancer1.jpeg';
import Freelancer7 from 'src/assets/images/mood/freelancer7.jpeg';
import Freelancer6 from 'src/assets/images/mood/freelancer6.jpeg';
import Freelancer8 from 'src/assets/images/mood/freelancer8.jpeg';
import { useAuth0 } from '@auth0/auth0-react';

const subscriptionData = [
  {
    title: 'Established Freelancer',
    description: 'For Freelancers who already have a company',
    features: [
      'AI-Powered Matching and Public Profile',
      'Slack Community and 360° Freelancer View',
      'Seamless Contracting Flow with Automated Invoicing',
    ],
    pricing: '10% on Marketplace Bookings',
    color: '#ffffff', // Light blue background for example
    imageUrl: Freelancer6, // Replace with your image path
  },
];

const SubscriptionCard = ({ title, description, features, pricing, color, imageUrl, index }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isDetailVisible, setDetailVisible] = useState(false);

  const handleReadMoreClick = () => {
    setDetailVisible(true);
  };

  const handleCloseClick = () => {
    setDetailVisible(false);
  };

  const isImageRight = index % 2 === 0;
  return (
    <Box sx={{ width: '100%', bgcolor: color }}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4} alignItems="center">
          {!isSmallScreen && isImageRight && (
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={imageUrl}
                alt={title}
                sx={{ width: '100%', height: 'auto' }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h3" component="div">
                {title}
              </Typography>
              <Typography variant="body1" color="text.secondary" gutterBottom marginBottom={3}>
                {description}
              </Typography>
              {features.map((feature, index) => (
                <Typography variant="subtitle1" component="p" key={index}>
                  - {feature}
                </Typography>
              ))}
              <Typography variant="subtitle1" component="p" sx={{ mt: 2 }}>
                {pricing}
              </Typography>
                {isDetailVisible && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      bgcolor: 'rgba(255, 255, 255, 0.9)',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 2,
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        cursor: 'pointer'
                      }}
                      onClick={handleCloseClick}
                    >
                      <Typography>X</Typography>
                    </Box>
                    <Typography variant="h5" sx={{ p: 2, textAlign: 'center' }}>
                      Detailed text goes here...
                    </Typography>
                    {/* You can include more detailed content here */}
                  </Box>
                )}

            </CardContent>
          </Grid>
          {!isSmallScreen && !isImageRight && (
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={imageUrl}
                alt={title}
                sx={{ width: '100%', height: 'auto' }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

const ValueProposition = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/dashboard",
      },
    });
  };
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/profile-setup",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: matches ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#FFEB00',
        py: 6,
        px: 3,
      }}
    >
      <Box sx={{ flex: matches ? '1' : 'unset', mx: matches ? 3 : 0, my: 3 }}>
        <Typography variant="h1"  component="h1" gutterBottom>
          Your profile is your brand
        </Typography>
        <Typography variant="subtitle1"  component="p" gutterBottom>
         Publishing yourself as a freelaner on Because Mondays propels your freelance career into the spotlight. We help you continue to build your profile, so you get matched with the perfect gigs for you.
        </Typography>
        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSignUp}>
          Join Because Mondays
        </Button>
      </Box>
      <Box
        component="img"
        sx={{ flex: matches ? '1' : 'unset', maxWidth: '650px', height: 'auto' }}
        src={Freelancer8} // Replace with your image path
        alt="Value Proposition"
      />
    </Box>
  );
};


const FreelancerLandingPage = () => {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/dashboard",
      },
    });
  };
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/profile-setup",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };
  
  return (
    <>
      <ValueProposition />

      <div style={{
      backgroundColor: 'white',
      padding: '50px',
      }} >
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" component="h1" gutterBottom marginTop={3}>
            How to use our services
          </Typography>
          <Typography variant="subtitle1" align="center">
            Create a profile, get matched, agree on a gig, sign a contract, work, invoice.
          </Typography>
        </Container>
      </div>

      {subscriptionData.map((subscription, index) => (
        <SubscriptionCard key={index} {...subscription} index={index} />
      ))}
      
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#FFEB00',
        py: 6,
        px: 3,
      }}
    >

    <Container maxWidth="lg">
      <Typography variant="h2" sx={{ mt: 4, mb: 3 }} align="center">
        Ready to Choose Your Path?
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 4, mb: 5 }} align="center">
        Let Because Mondays be your guide to your freelance career.
      </Typography>
      <Box display="flex" justifyContent="center" marginBottom={4}>
        <Button variant="contained" color='primary' size="large" onClick={handleSignUp}>
          Join Because Mondays
        </Button>
      </Box>
    </Container>


      </Box>

      {/* <Box sx={{ bgcolor: 'white', py: 6 }}>
        <Container maxWidth="lg">
          <Divider />
          <FeatureComparisonMatrix />
        </Container>
      </Box> */}
    </>
  );
};

export default FreelancerLandingPage;
