import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  CardContent,
  Button,
  CardMedia,
  useMediaQuery,
  useTheme,
  Grid,
} from '@mui/material';
import Freelancer4 from 'src/assets/images/mood/freelancer4.jpeg';
import Freelancer8 from 'src/assets/images/mood/freelancer8.jpeg';
import { useAuth0 } from '@auth0/auth0-react';

const subscriptionData = [
  {
    title: 'Find and Book Freelancers',
    description: 'For businesses who want to find the right freelancer, we are making the journey even easier. We’ve partnered with Let’s Gig who will act as a broker and support business looking for help.',
    features: [
      'Our AI powered matching will use our personality-based, skill-specific matching process to connect you with the best suited freelancer for your project.', 
      'Let’s Gig will be your primary point of contact.',
      'Let’s Gig will take care of contracts and invoice routine.',
    ],
    pricing: '5% Service Fee',
    color: '#ffffff', 
    imageUrl: "https://res.cloudinary.com/dldyopnvj/image/upload/v1706168901/Screenshot_2024-01-25_at_08.44.09_xf58ei.png", 
  },
];

const SubscriptionCard = ({ title, description, features, pricing, color, imageUrl, index }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isDetailVisible, setDetailVisible] = useState(false);

  const handleCloseClick = () => {
    setDetailVisible(false);
  };

  const isImageRight = index % 2 === 0;
  return (
    <Box sx={{ width: '100%', bgcolor: color }}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4} alignItems="center">
          {!isSmallScreen && isImageRight && (
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={imageUrl}
                alt={title}
                sx={{ width: '100%', height: 'auto' }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h3" component="div">
                {title}
              </Typography>
              <Typography variant="body1" color="text.secondary" gutterBottom marginBottom={3}>
                {description}
              </Typography>
              {features.map((feature, index) => (
                <Typography variant="subtitle1" component="p" key={index}>
                  - {feature}
                </Typography>
              ))}
              {/* <Typography variant="subtitle1" component="p" sx={{ mt: 2 }}>
                {pricing}
              </Typography> */}

            </CardContent>
          </Grid>
          {!isSmallScreen && !isImageRight && (
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={imageUrl}
                alt={title}
                sx={{ width: '100%', height: 'auto' }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

const ValueProposition = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/dashboard",
      },
    });
  };
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/profile-setup",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: matches ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#FFEB00',
        py: 6,
        px: 3,
      }}
    >
      <Box sx={{ flex: matches ? '1' : 'unset', mx: matches ? 3 : 0, my: 3 }}>
        <Typography variant="h1"  component="h1" gutterBottom>
          Publish Projects, Find the right Freelancer, Book
        </Typography>
        <Typography variant="subtitle1"  component="p" gutterBottom>
         Introducing projects on Because Mondays connects you with top-tier freelancers, who will help transform your vision into reality. Our platform ensures your project finds a match—connecting the right person with the right problem.
        </Typography>
        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSignUp}>
          Sign up to Because Mondays
        </Button>
        <br/>
      </Box>
      <Box
        component="img"
        sx={{ flex: matches ? '1' : 'unset', maxWidth: '650px', height: 'auto' }}
        src={"https://res.cloudinary.com/dldyopnvj/image/upload/v1706168916/Screenshot_2024-01-25_at_08.43.57_mpxcy2.png"}
        alt="Value Proposition"
      />
    </Box>
  );
};


const ClientLandingPage = () => {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/dashboard",
      },
    });
  };
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/profile-setup",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  return (
    <>
      <ValueProposition />
     

        <div style={{
        backgroundColor: 'white',
        padding: '50px',
        }} >
          <Container maxWidth="lg">
            <Typography variant="h2" align="center" component="h1" gutterBottom marginTop={3}>
              The benefits of booking freelancers with Because Mondays
            </Typography>
            <Typography variant="subtitle1" align="center">
              We want the best match to happen. The right freelancer for the right customer. We 
              believe values are as important as skills. 
              Match your project needs with the right freelancer.
            </Typography>
          </Container>
        </div>

        {subscriptionData.map((subscription, index) => (
          <SubscriptionCard key={index} {...subscription} index={index} />
        ))}
        <div style={{
          backgroundColor: '#ECF2FF',
          padding: '50px',
        }} >
          <Container maxWidth="lg" style={{ backgroundColor: 'white', padding: '50px' }}>
            <Typography variant="h2" align="center" component="h1" gutterBottom marginTop={3}>
              How it Works
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 4, mb: 5 }} align="center">
              Our system will guide you through this process.
            </Typography>
            <Grid container spacing={10} justifyContent="center" marginTop={3}>
              <Grid item xs={12} md={4}>
              <Typography variant="h5" align="center">1. Talk to us about your challenge.</Typography>
              <Typography align="center">Send an email to hello@becausemondays.com so we can talk about your challenge.</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" align="center">2. We'll publish the project</Typography>
                <Typography align="center">We post your project details and requirements for freelancers to see.
                Our AI will start to work and send you matching profiles within minutes.</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" align="center">3. Get Offers</Typography>
                <Typography align="center">Freelancers will make offers or present themselves for your project.</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" align="center">4. Sign Deal</Typography>
                <Typography align="center">Once you´ve found the right candidate, sign a deal with Because Mondays AB to secure your collaboration.</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" align="center">5. Start Work</Typography>
                <Typography align="center">Begin the project with your chosen freelancer.</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" align="center">6. Monthly Invoicing</Typography>
                <Typography align="center">Receive invoices and make payments on a monthly basis.</Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#FFEB00',
          py: 6,
          px: 3,
        }}
      >

        <Container maxWidth="lg">
          <Typography variant="h2" sx={{ mt: 4, mb: 3 }} align="center">
            Ready to try us out?
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 4, mb: 5 }} align="center">
            Let Because Mondays guide your freelance hiring process.
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color='primary' size="large" onClick={handleSignUp}>
              Sign up to Because Mondays
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" marginBottom={4}>
          </Box>
        </Container>
      </Box>
    </>
   
  );
};

export default ClientLandingPage;
